































































import {Component, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  ProductMappingType,
  ProductMappingCreateOrUpdateDto,
  ProductDto, ProductBasicParamDto, FeedbackReplyDto, ProductSkuDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import AbSelect from "@/components/AbSelect.vue";
import ExportButton from "@/components/ExportButton.vue";
import SelectOrderCommodity from "@/views/productSku/order/selectOrderCommodity.vue";

@Component({
  components: {
    SelectOrderCommodity,
    PagedTableView,
    ExportButton,
    AbSelect,
  },
})
export default class NeededList extends Vue {
  queryForm = {
    sourceProductId: parseInt(this.$route.params.id!),
    type: ProductMappingType.Needed,
  };
  form: ProductMappingCreateOrUpdateDto = {
    sourceProductId: undefined,
    targetProductIds: [],
    targetProductSkuIds: [],
    type: undefined,
  };

  isSubmitting = false;

  fetchData(params: never) {
    return api.productMapping.getAll(params);
  }

  // 选择商品
  handleSelectCommodity() {
    (this.$refs.selectFrom as any).show = true;
  }


  productList: ProductDto[] = [];

  getSelectionProduct(productList: any) {
    if (productList || productList.length > 0) {
      console.log("ids=" + productList);
      this.productList = [];
      this.productList = productList;
    }
    this.form.targetProductIds = [];
    this.form.targetProductSkuIds = [];
    this.productList.forEach((item) => {
      if (item.id != null) {
        this.form.targetProductIds?.push(item.id);
      }
    })
    this.handleSave();
  }


  handleSave() {
    this.isSubmitting = true;
    this.form.sourceProductId = parseInt(this.$route.params.id!);
    this.form.type = ProductMappingType.Needed;
    api.productMapping.create({body: this.form}).then(() => {
      this.$message.success((this as any).$l.getLanguageText("basic.updateSuccess").toString());
    }).finally(() => {
      this.isSubmitting = false;
    });
  }

  handleDelete(index: number, row: ProductBasicParamDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.deleteTip").toString(),
      (this as any).$l.getLanguageText("basic.tip").toString()
    ).then(async () => {
      await api.productMapping.delete({id: row.id}).then((res) => {
        this.$message({
          type: "success",
          message: (this as any).$l.getLanguageText("basic.deleteSuccess").toString(),
        });
      });
    });
  }
}
