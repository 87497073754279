






















































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import {
  ProductBrandDto,
  ProductCategoryDto,
  ProductDto,
  ProductSkuDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import { ElForm } from "element-ui/types/form";
import { createNgTree, INgNode } from "@/utils/tree";

@Component({
  name: "SelectOrderCommodity",
  components: { PagedTableView },
})
export default class SelectOrderCommodity extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly pagedTableViewRef!: PagedTableView;

  @Prop({ required: false, default: [] })
  notShowProductIdList?: number[];

  queryForm = {
    name: undefined,
    notShowProductIds: "",
  };
  show = false;
  loading = true;
  selectionList: ProductDto[] = [];
  products: ProductDto[] = [];
  productBrandList: ProductBrandDto[] = [];
  categories: ProductCategoryDto[] = [];
  categoriesTree: INgNode<ProductCategoryDto>[] = [];
  categoryList = [] as ProductCategoryDto[];
  categoryTree: INgNode<ProductCategoryDto>[] = [];
  parentIdPath = "";
  parentId = 0;

  @Watch("notShowProductIdList", { deep: true, immediate: true })
  notShowProductIdListChange(newVal?: number[]) {
    if (newVal) {
      this.queryForm.notShowProductIds = newVal.join(",");
    } else {
      this.queryForm.notShowProductIds = "";
    }
    (this.$refs.pagedTableViewRef as any)?.refreshData();
  }

  //获取全部商品类别
  async fetchClassificationList() {
    let _this = this as any;
    await api.productCategory
      .getAll({
        skipCount: 0,
        maxResultCount: 65535,
      })
      .then((res) => {
        this.categoryList = res.items ?? [];
        this.categoryTree = createNgTree(
          res.items ?? [],
          "parentId",
          "id",
          null,
          "children",
          null,
          false,
          "id",
          "fullName"
        );
      });
  }

  save() {
    // this.dataIds = [];
    if (!this.selectionList || this.selectionList.length <= 0) {
      this.$message({
        type: "error",
        message: (this as any).$l.getLanguageText(
          "startOrderOf.atLeastOnePieceOfData"
        ) as string,
      });
      return;
    }
    this.$confirm(
      (this as any).$l.getLanguageText("startOrderOf.batchSelection") as string,
      (this as any).$l.getLanguageText("basic.tip") as string
    ).then(async () => {
      this.products = this.selectionList;
      this.$emit("productSkus", this.products);
      this.show = false;
    });
  }

  cascaderClick(value: any) {
    this.parentIdPath = value.data.parentIdPath;
    let _this = this as any;
    _this.$refs.cascader.checkedValue = value.id;
    _this.$refs.cascader.computePresentText();
    _this.$refs.cascader.toggleDropDownVisible(false);
  }

  // 获取已选择数据
  getSelectionList(rows: any) {
    this.selectionList = rows;
  }

  async created() {
    await this.fetchProductBrandList();
    await this.fetchCategories();
    await this.fetchClassificationList();
  }

  fetchProductBrandList() {
    api.productBrand.getAll({ maxResultCount: 65535 }).then((res: any) => {
      this.productBrandList = res!.items;
    });
  }

  // 获取表数据
  fetchData(params: any) {
    if (this.$route.params.code) {
      params.customerCode = this.$route.params.code;
    }
    return api.product.getAll({ ...params, parentIdPath: this.parentIdPath });
  }

  fetchCategories() {
    api.productCategory.getAll({ maxResultCount: 1000 }).then((res) => {
      this.categories = res.items!;
      this.categoriesTree = createNgTree(
        res.items!,
        "parentId",
        "id",
        null,
        "children",
        null,
        false,
        "id",
        "fullName"
      );
    });
  }

  // handleCategoriesChange(value: any) {
  //   this.queryForm!.parentIdPath = value![value.length - 1];
  // }

  cancel() {
    this.show = false;
  }
}
