















import {Component, Vue} from "vue-property-decorator";
import RecommendList from "@/views/commodity/commodity/components/recommendList.vue";
import NeededList from "@/views/commodity/commodity/components/neededList.vue";

@Component({
  components: {
    NeededList,
    RecommendList,
  },
})

export default class ProjectMapping extends Vue {
  activeName = "first"

}
