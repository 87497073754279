var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"close-on-click-modal":false,"title":_vm.$l.getLanguageText('startOrderOf.selectCommodity'),"visible":_vm.show,"width":"1200px"},on:{"update:visible":function($event){_vm.show=$event},"close":_vm.cancel}},[_c('paged-table-view',{ref:"pagedTableViewRef",attrs:{"search-function":_vm.fetchData,"query-form":_vm.queryForm},on:{"selectionList":_vm.getSelectionList},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-cascader',{ref:"cascader",attrs:{"options":_vm.categoryTree,"props":{
            value: 'id',
            checkStrictly: true,
            expandTrigger: 'hover',
            emitPath: false,
          },"show-all-levels":false,"clearable":"","placeholder":_vm.$l.getLanguageText('startOrderOf.parentIdPath')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var node = ref.node;
          var data = ref.data;
return [_c('div',{on:{"click":function($event){return _vm.cascaderClick(data)}}},[_c('span',[_vm._v(_vm._s(data.label))]),(!node.isLeaf)?_c('span',[_vm._v(" ("+_vm._s(data.children.length)+") ")]):_vm._e()])]}}]),model:{value:(_vm.parentId),callback:function ($$v) {_vm.parentId=$$v},expression:"parentId"}})],1),_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","type":"text","placeholder":_vm.$l.getLanguageText('commodity.name')},model:{value:(_vm.queryForm.name),callback:function ($$v) {_vm.$set(_vm.queryForm, "name", $$v)},expression:"queryForm.name"}})],1)]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"type":"selection","width":"55"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('basic.index'),"type":"index","width":"50"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('commodity.productName'),"align":"center","prop":"fullName"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('commodity.brandName'),"align":"center","prop":"fkBrand.fullName"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('commodity.parentNamePath'),"align":"center","prop":"fkCategory.parentNamePath"}}),_c('el-table-column',{attrs:{"label":_vm.$l.getLanguageText('commodity.totalStock'),"align":"center","prop":"totalStock"}})]},proxy:true}])}),_c('div',{staticClass:"button-position",staticStyle:{"align-content":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"default"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.cancel")))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$l.getLanguageText("basic.confirm")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }